import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'assets/styles/main.scss';
import reportWebVitals from './reportWebVitals';
import './translate';
import { Userpilot } from 'userpilot';

// eslint-disable-next-line
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector('#app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.info(process.env.REACT_APP_NAME, process.env.REACT_APP_VERSION);

Userpilot.initialize('61vm90j8', {
  domain: 'proxy.skolon.com/userpilot',
  endpoint: 'proxy.skolon.com/userpilot-ws',
});
